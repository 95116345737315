<template>
  <div>
    <h1>Payment Page</h1>
    <a-form :form="form">
      <a-form-item label="Name on Card">
        <a-input
          prefix-icon="user"
          placeholder="Enter name on card"
          v-decorator="[
            'name',
            {
              rules: [{ required: true, message: 'Please enter name on card' }],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="Card Number">
        <a-input
          prefix-icon="credit-card"
          placeholder="Enter card number"
          v-decorator="[
            'cardNumber',
            {
              rules: [{ required: true, message: 'Please enter card number' }],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="Expiry Date">
        <a-input-group compact>
          <a-select
            style="width: 50%"
            placeholder="Month"
            v-decorator="[
              'expiryMonth',
              {
                rules: [
                  { required: true, message: 'Please select expiry month' },
                ],
              },
            ]"
          >
            <a-select-option value="01">January</a-select-option>
            <a-select-option value="02">February</a-select-option>
            <a-select-option value="03">March</a-select-option>
            <a-select-option value="04">April</a-select-option>
            <a-select-option value="05">May</a-select-option>
            <a-select-option value="06">June</a-select-option>
            <a-select-option value="07">July</a-select-option>
            <a-select-option value="08">August</a-select-option>
            <a-select-option value="09">September</a-select-option>
            <a-select-option value="10">October</a-select-option>
            <a-select-option value="11">November</a-select-option>
            <a-select-option value="12">December</a-select-option>
          </a-select>
          <a-input
            style="width: 50%"
            placeholder="Year"
            v-decorator="[
              'expiryYear',
              {
                rules: [
                  { required: true, message: 'Please select expiry year' },
                ],
              },
            ]"
          />
        </a-input-group>
      </a-form-item>
      <a-form-item label="CVV">
        <a-input-password
          prefix-icon="lock"
          placeholder="Enter CVV"
          v-decorator="[
            'cvv',
            {
              rules: [{ required: true, message: 'Please enter CVV' }],
            },
          ]"
        />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="handleSubmit"> Pay Now </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
export default {
  name: "PaymentPage",

  data() {
    return {
      form: this.$form.createForm(this, {
        onFieldsChange: (changedFields, allFields) => {
          console.log(changedFields, allFields);
        },
      }),
    };
  },
  methods: {
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          // You can perform your payment processing logic here
        }
      });
    },
  },
};
</script>
